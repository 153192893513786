import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/custom.css";
import "./assets/css/main.css";
import "./assets/css/sidebar.css";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "/node_modules/bootstrap/dist/js/bootstrap.min.js";
// import "react-datepicker/dist/react-datepicker.css";
import "../src/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
const DataEntry = lazy(() => import("./components/DashBoard/DataEntry"));
const Dashboard = lazy(() => import("./components/DashBoard/Dashboard/index"));
const Roles = lazy(() => import("./components/DashBoard/Roles/index"));
const Root = lazy(() => import("./Root"));
const Groups = lazy(() => import("./components/DashBoard/Groups"));
const Users = lazy(() => import("./components/DashBoard/Users"));
const Create = lazy(() => import("./components/DashBoard/Roles/Create"));
const Payee = lazy(() => import("./components/DashBoard/Payee/index"));
const CreatePayee = lazy(() =>
  import("./components/DashBoard/Payee/CreatePayee/index")
);
const CreateGroup = lazy(() => import("./components/DashBoard/Groups/Create"));
const EditGroup = lazy(() => import("./components/DashBoard/Groups/Edit"));
const EditPayee = lazy(() =>
  import("./components/DashBoard/Payee/EditPayee/index")
);
const Payer = lazy(() => import("./components/DashBoard/Payer/index"));
const CreatePayer = lazy(() =>
  import("./components/DashBoard/Payer/CreatePayer/index")
);
const EditPayer = lazy(() =>
  import("./components/DashBoard/Payer/EditPayer/index")
);
const CreateUser = lazy(() =>
  import("./components/DashBoard/Users/CreateUser")
);
const EditUser = lazy(() => import("./components/DashBoard/Users/EditUser"));
const EditRole = lazy(() => import("./components/DashBoard/Roles/EditRole"));
const ClientOnBoard = lazy(() =>
  import("./components/DashBoard/ClientOnBoard/index")
);
const CreateClient = lazy(() =>
  import("./components/DashBoard/ClientOnBoard/CreateClient/index")
);
const ReasonCode = lazy(() =>
  import("./components/DashBoard/ResonCodes/index")
);
const CreateReasonCode = lazy(() =>
  import("./components/DashBoard/ResonCodes/CreateReasonCode/index")
);
const EditReasonCode = lazy(() =>
  import("./components/DashBoard/ResonCodes/EditReasonCode/index")
);
const X12Code = lazy(() => import("./components/DashBoard/X12Code/index"));
const CreateX12Code = lazy(() =>
  import("./components/DashBoard/X12Code/CreateX12Code/index")
);
const EditX12Code = lazy(() =>
  import("./components/DashBoard/X12Code/EditX12Code/index")
);
const CorrespondenceCategory = lazy(() =>
  import("./components/DashBoard/CorrespondenceCategory/index")
);
const CreateCorrespondenceCategory = lazy(() =>
  import("./components/DashBoard/CorrespondenceCategory/Create/index")
);
const EditCorrespondenceCategory = lazy(() =>
  import("./components/DashBoard/CorrespondenceCategory/Edit/index")
);
import MainIndex from "./components/DashBoard/Admin/index";
import Footer from "./Layout/admin/Includes/Footer";
import rootLoader from "./assets/animations/root-loader.json";
import {
  DASHBOARD,
  IFRAME,
  INDEX,
  ROLES,
  CREATE_ROLES,
  EDIT_ROLES,
  GROUPS,
  CREATE_GROUPS,
  EDIT_GROUPS,
  PAYEE,
  CREATE_PAYEE,
  EDIT_PAYEE,
  PAYER,
  EDIT_PAYER,
  USERS,
  EDIT_USERS,
  CREATE_USERS,
  DATA_ENTRY,
  CLIENT,
  CREATE_CLIENT,
  CREATE_PAYER,
  HOLIDAY_CALENDAR,
  EDIT_HOLIDAY_CALENDAR,
  CREATE_HOLIDAY_CALENDAR,
  REASON_CODES,
  CREATE_REASON_CODES,
  X12_CODES,
  CREATE_X12_CODES,
  EDIT_X12_CODES,
  CORRESPONDENCE_CODES,
  CREATE_CORRESPONDENCE_CODE,
  EDIT_REASON_CODES,
  EDIT_CORRESPONDENCE_CODE,
  SETTINGS,
  FACILITIES,
  EDIT_CLIENT,
  FILE_SPLIT,
  QMS,
  QMSQACOMPlETED,
  EDIT_WORK,
  SINGLE_SPLIT,
  LOCKBOXFILES,
  LOCKBOX,
  SINGLE_LOCKBOX_LOCKBOX_FILES,
  REJECT_REASONS,
  CREATE_REJECT_REASONS,
  EDIT_REJECT_REASONS,
  CLIENT_GROUP,
  CREATE_CLIENT_GROUP,
  EDIT_CLIENT_GROUP,
  CREATE_ASSIGN_USERS,
  COPY_CLIENT,
  GET_PATIENT_PAY,
  GET_CORRESPONDANCE,
  GET_ENCOUNTER_LIST,
  GET_835_LIST,
  SFTP_CLIENT_LIST,
  QA_REVIEW,
  APPROVE_PAYEE,
  GET_835_LITE_LIST,
  NOTIFICATION_LIST,
  PARTNER_LIST,
  PARTNER_CREATE,
  PARTNER_EDIT,
  DOCUMENT_CLASSIFICATION,
  CREATE_DOCUMENT,
  EDIT_DOCUMENT,
  CREATE_REASON,
  REASON,
  EDIT_REASON,
  CREATE_CLAIMS_FILING,
  CLAIMS_FILING,
  EDIT_CLAIMS_FILING,
  NOTIFICATION_EDIT,
  LOCKBOX_FILES_QMS,
  EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATE,
  CREATE_EMAIL_TEMPLATE,
  DBK_LIST,
  CREATE_DBK,
  EDIT_DBK,
  LOGIN,
  SFTP_LOG_LIST,
  OFFLINE_STATUS,
  EMAIL_LOG_LIST,
  HOUSE_KEEPING_LOG_LIST,
  GET_ACKNOWLEDGMENT_LIST,
  SNIPPET,
  PAYER_ID_LIST,
  EDIT_PAYER_ID_LIST,
  CREATE_PAYER_ID_LIST,
  GET_REPORT_LIST,
  RETRIVE_SPRING_LOCKBOX,
} from "./routeNames";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "/node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../src/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import { createRoutesFromElements, RouterProvider } from "react-router-dom";
import CustomLoading from "./components/Loader/CustomLoading";

const HolidayCalendar = lazy(() =>
  import("./components/DashBoard/HolidayCalendar/index")
);
const EditHolidayCalendar = lazy(() =>
  import("./components/DashBoard/HolidayCalendar/EditCalendar/index")
);
const CreateCalendar = lazy(() =>
  import("./components/DashBoard/HolidayCalendar/CreateCalendar/index")
);
const ErrorLoader = lazy(() => import("./Elements/ErrorLoader"));
const Settings = lazy(() => import("./components/Settings"));
const Facility = lazy(() =>
  import("./components/DashBoard/ClientOnBoard/CreateClient/Forms/facility")
);
const Login = lazy(() => import("./components/Login/index"));
const FileSplit = lazy(() => import("./components/DashBoard/FileSplit"));
const QueueManagementSystem = lazy(() =>
  import("./components/DashBoard/QueueManagementSystem")
);
const QaCompleted = lazy(() => import("./components/DashBoard/QaCompleted"));
const PdfViewer = lazy(() =>
  import("./components/DashBoard/FileSplit/PdfViewer")
);
const AllLockbox = lazy(() => import("./components/DashBoard/AllLockboxFiles"));
const Iframe = lazy(() => import("./components/Iframe"));
const Lockbox = lazy(() => import("./components/DashBoard/Lockbox"));
const RejectReason = lazy(() => import("./components/DashBoard/RejectReason"));
const CreateRejectReason = lazy(() =>
  import("./components/DashBoard/RejectReason/CreateRejectReason")
);
const ClientGroup = lazy(() => import("./components/DashBoard/ClientGroup"));
const CreateClientGroup = lazy(() =>
  import("./components/DashBoard/ClientGroup/CreateClient")
);
const CreateAssignUser = lazy(() =>
  import("./components/DashBoard/ClientGroup/CreateAssignUser")
);
const CorrespondanceList = lazy(() =>
  import("./components/DashBoard/OutputSchema/CorrespondenceList")
);
const PatientPayList = lazy(() =>
  import("./components/DashBoard/OutputSchema/PatientPayList")
);
const EncounterList = lazy(() =>
  import("./components/DashBoard/OutputSchema/EncounterList")
);
const List835 = lazy(() =>
  import("./components/DashBoard/OutputSchema/List835")
);
const ListAcknowledgement = lazy(() =>
  import("./components/DashBoard/OutputSchema/ListAcknowledgement")
);
const SftpClientList = lazy(() =>
  import("./components/DashBoard/SftpClient/SftpClientList")
);
const List835Lite = lazy(() => import("./components/DashBoard/835Lite"));
const NotificationList = lazy(() =>
  import("./components/DashBoard/Notification")
);
const View = lazy(() => import("./components/DashBoard/Partner/View"));
const ViewDocument = lazy(() => import("./components/DashBoard/Document/View"));
const CreateUpdate = lazy(() =>
  import("./components/DashBoard/Partner/CreateUpdate")
);
const CreateUpdateDocument = lazy(() =>
  import("./components/DashBoard/Document/createUpdate")
);
const EditDocument = lazy(() =>
  import("./components/DashBoard/Document/EditDocument")
);
const ViewReason = lazy(() => import("./components/DashBoard/Reason/View"));
const CreateUpdateReason = lazy(() =>
  import("./components/DashBoard/Reason/CreateUpdate")
);
const EditReason = lazy(() =>
  import("./components/DashBoard/Reason/EditReason")
);
const EditNotification = lazy(() =>
  import("./components/DashBoard/Notification/EditNotification")
);
const ViewClaimFiling = lazy(() =>
  import("./components/DashBoard/ClaimFilling/View")
);
const CreateUpdateClaimsFiling = lazy(() =>
  import("./components/DashBoard/ClaimFilling/CreateUpdateClaimsFiling")
);
const Verthe = lazy(() => import("./verthe"));
const Njan = lazy(() => import("./njan"));
const ViewEmailTemplate = lazy(() =>
  import("./components/DashBoard/EmailTemplate/ViewEmailTemplate")
);
const CreateUpdateEmailTemplate = lazy(() =>
  import("./components/DashBoard/EmailTemplate/CreateUpdateEmailTemplate")
);
const ViewDbk = lazy(() => import("./components/DashBoard/Dbk"));
const CreateUpdateDbk = lazy(() =>
  import("./components/DashBoard/Dbk/CreateUpdateDbk")
);
const SftpLogList = lazy(() => import("./components/DashBoard/SftpLog"));
const ViewOfflineStatus = lazy(() =>
  import("./components/DashBoard/OfflineStatus/view")
);
const EmailLogList = lazy(() => import("./components/DashBoard/EmailLog"));
const HouseKeepingLogList = lazy(() =>
  import("./components/DashBoard/HouseKeepingLog")
);
const SnippetView = lazy(() =>
  import("./components/DashBoard/Snippet/SnippetView")
);
const PayerIdList = lazy(() => import("./components/DashBoard/PayerIdList"));
const CreatePayerIdList = lazy(() =>
  import("./components/DashBoard/PayerIdList/CreatePayerIdList")
);
const ListReport = lazy(() =>
  import("./components/DashBoard/OutputSchema/ListReport")
);
const SpringLockbox = lazy(() =>
  import("./components/DashBoard/SpringLockbox")
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorLoader />}>
      <Route
        errorElement={<ErrorLoader />}
        index
        path={INDEX}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Verthe />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        index
        path={"/njan"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Njan />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        index
        path={LOGIN}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        index
        path={IFRAME}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Iframe title="iframe" />
          </Suspense>
        }
      />

      <Route
        errorElement={<ErrorLoader />}
        path={DASHBOARD}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Dashboard />
          </Suspense>
        }
      />

      {/* Roles managment starts */}
      <Route
        errorElement={<ErrorLoader />}
        path={ROLES}
        element={
          <Suspense fallback={<div>Loading Roles...</div>}>
            <Roles />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_ROLES}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Create />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_ROLES + ":role_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EditRole />
          </Suspense>
        }
      />
      {/* Roles managment ends */}

      {/* group managment starts */}
      <Route
        errorElement={<ErrorLoader />}
        path={GROUPS}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Groups />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_GROUPS}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateGroup />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_GROUPS + ":group_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EditGroup />
          </Suspense>
        }
      />
      {/* group managment ends */}

      {/* payee managment starts */}
      <Route
        errorElement={<ErrorLoader />}
        path={PAYEE}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Payee />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_PAYEE + ":payee_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EditPayee />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={APPROVE_PAYEE + ":payee_id/:type"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EditPayee />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_PAYEE}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreatePayee />
          </Suspense>
        }
      />
      {/* payee managment ends */}

      {/* payer managment starts */}
      <Route
        errorElement={<ErrorLoader />}
        path={PAYER}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Payer />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_PAYER + ":payer_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EditPayer />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_PAYER + ":payer_id/:type"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EditPayer />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_PAYER}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreatePayer />
          </Suspense>
        }
      />
      {/* payer managment ends */}
      {/* payer id list managment starts */}
      <Route
        errorElement={<ErrorLoader />}
        path={PAYER_ID_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <PayerIdList />
          </Suspense>
        }
      />

      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_PAYER_ID_LIST + ":id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreatePayerIdList />
          </Suspense>
        }
      />

      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_PAYER_ID_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreatePayerIdList />
          </Suspense>
        }
      />
      {/* payer id list managment ends */}

      {/* user managment starts */}
      <Route
        errorElement={<ErrorLoader />}
        path={USERS}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Users />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_USERS + ":user_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EditUser />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_USERS}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateUser />
          </Suspense>
        }
      />
      {/* payer managment ends */}

      {/* data entry Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={DATA_ENTRY}
        element={
          <Suspense fallback={<CustomLoading />}>
            <DataEntry />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={QA_REVIEW}
        element={
          <Suspense fallback={<CustomLoading />}>
            <DataEntry />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={SNIPPET}
        element={
          <Suspense fallback={<CustomLoading />}>
            <SnippetView />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_WORK + ":job_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <DataEntry />
          </Suspense>
        }
      />
      {/* data entry Routes ends*/}

      {/* staff Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={FILE_SPLIT}
        element={
          <Suspense fallback={<CustomLoading />}>
            <FileSplit />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={SINGLE_SPLIT + ":file_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <PdfViewer />
          </Suspense>
        }
      />
      {/* staff Routes ends*/}

      {/* client onboard Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={CLIENT}
        element={
          <Suspense fallback={<CustomLoading />}>
            <ClientOnBoard />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_CLIENT}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateClient />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_CLIENT + ":id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateClient />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={COPY_CLIENT + ":copy_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateClient />
          </Suspense>
        }
      />
      {/* client onboard Routes ends*/}

      {/* client group onboard Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={CLIENT_GROUP}
        element={
          <Suspense fallback={<CustomLoading />}>
            <ClientGroup />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_CLIENT_GROUP}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateClientGroup />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_CLIENT_GROUP}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateClientGroup />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_ASSIGN_USERS}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateAssignUser />
          </Suspense>
        }
      />
      {/* client group onboard Routes ends*/}

      {/* Holiday calendar Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={HOLIDAY_CALENDAR}
        element={
          <Suspense fallback={<CustomLoading />}>
            <HolidayCalendar />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_HOLIDAY_CALENDAR}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateCalendar />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_HOLIDAY_CALENDAR + ":id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateCalendar />
          </Suspense>
        }
      />
      {/* Holiday calendar Routes ends*/}

      {/* Reason codes Routes */}
      <Route
        path={REASON_CODES}
        element={
          <Suspense fallback={<CustomLoading />}>
            <ReasonCode />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_REASON_CODES}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateReasonCode />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_REASON_CODES + ":id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateReasonCode />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_REASON_CODES + ":id/:type"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateReasonCode />
          </Suspense>
        }
      />
      {/* Re errorElement={<ErrorLoader />}ason codes Routes ends*/}

      {/* X1 errorElement={<ErrorLoader />}2 codes Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={X12_CODES}
        element={
          <Suspense fallback={<CustomLoading />}>
            <X12Code />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_X12_CODES}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateX12Code />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_X12_CODES + ":id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EditX12Code />
          </Suspense>
        }
      />
      {/* X12 codes Routes ends*/}

      {/* CORRESPONDENCE Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={CORRESPONDENCE_CODES}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CorrespondenceCategory />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_CORRESPONDENCE_CODE}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateCorrespondenceCategory />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_CORRESPONDENCE_CODE + ":id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EditCorrespondenceCategory />
          </Suspense>
        }
      />
      {/* CORRESPONDENCE END Routes */}

      {/* Reject reason Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={REJECT_REASONS}
        element={
          <Suspense fallback={<CustomLoading />}>
            <RejectReason />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_REJECT_REASONS}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateRejectReason />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_REJECT_REASONS + ":id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateRejectReason />
          </Suspense>
        }
      />

      {/* Reject reasons END Routes */}

      {/* CORRESPONDENCE Routes */}
      <Route
        path={FACILITIES}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Facility />
          </Suspense>
        }
      />
      {/* CORRESPONDENCE END Routes */}

      {/* SFTP Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={SFTP_CLIENT_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <SftpClientList />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={SFTP_LOG_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <SftpLogList />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={HOUSE_KEEPING_LOG_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <HouseKeepingLogList />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EMAIL_LOG_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EmailLogList />
          </Suspense>
        }
      />
      {/* SFTP END Routes */}

      {/* LOCKBOX Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={LOCKBOX}
        element={
          <Suspense fallback={<CustomLoading />}>
            <AllLockbox />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={LOCKBOXFILES}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Lockbox />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={SINGLE_LOCKBOX_LOCKBOX_FILES + ":file_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Lockbox />
          </Suspense>
        }
      />
      {/* LOCKBOX END Routes */}

      {/* SETTINGS Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={SETTINGS}
        element={
          <Suspense fallback={<CustomLoading />}>
            <Settings />
          </Suspense>
        }
      />
      {/* SETTINGS END Routes */}

      {/* OFFLINE_STATUS Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={OFFLINE_STATUS}
        element={
          <Suspense fallback={<CustomLoading />}>
            <ViewOfflineStatus />
          </Suspense>
        }
      />
      {/* OFFLINE_STATUS END Routes */}

      {/* QMS Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={QMS}
        element={
          <Suspense fallback={<CustomLoading />}>
            <QueueManagementSystem />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={LOCKBOX_FILES_QMS + ":lockbox_file"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <QueueManagementSystem />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={QMSQACOMPlETED}
        element={
          <Suspense fallback={<CustomLoading />}>
            <QaCompleted />
          </Suspense>
        }
      />
      {/* QMS END Routes */}

      {/* OutputSchema */}
      <Route
        errorElement={<ErrorLoader />}
        path={GET_PATIENT_PAY}
        element={
          <Suspense fallback={<CustomLoading />}>
            <PatientPayList />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={GET_CORRESPONDANCE}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CorrespondanceList />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={GET_ENCOUNTER_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EncounterList />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={GET_835_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <List835 />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={GET_ACKNOWLEDGMENT_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <ListAcknowledgement />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={GET_REPORT_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <ListReport />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={GET_835_LITE_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <List835Lite />
          </Suspense>
        }
      />

      <Route
        errorElement={<ErrorLoader />}
        path={NOTIFICATION_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <NotificationList />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={NOTIFICATION_EDIT + ":notification_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EditNotification />
          </Suspense>
        }
      />

      {/* partner */}
      <Route
        errorElement={<ErrorLoader />}
        path={PARTNER_CREATE}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateUpdate />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={PARTNER_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <View />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={PARTNER_EDIT + ":partner_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateUpdate />
          </Suspense>
        }
      />

      {/* partner */}

      {/* DOCUMENT */}

      <Route
        errorElement={<ErrorLoader />}
        path={DOCUMENT_CLASSIFICATION}
        element={
          <Suspense fallback={<CustomLoading />}>
            <ViewDocument />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_DOCUMENT}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateUpdateDocument />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_DOCUMENT + ":classification"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EditDocument />
          </Suspense>
        }
      />

      {/* REASON */}
      <Route
        errorElement={<ErrorLoader />}
        path={REASON}
        element={
          <Suspense fallback={<CustomLoading />}>
            <ViewReason />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_REASON}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateUpdateReason />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_REASON + ":reason_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <EditReason />
          </Suspense>
        }
      />
      {/* <Route
        errorElement={<ErrorLoader />}
        path={VIEW_LOG_DETAIL + ":log_id"}
        element={
        <Suspense fallback={<CustomLoading />}>
            <ViewLogDetails />
          </Suspense>
        }
      /> */}
      <Route
        errorElement={<ErrorLoader />}
        path={CLAIMS_FILING}
        element={
          <Suspense fallback={<CustomLoading />}>
            <ViewClaimFiling />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_CLAIMS_FILING}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateUpdateClaimsFiling />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_CLAIMS_FILING + ":claimsFiling_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateUpdateClaimsFiling />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_CLAIMS_FILING + ":claimsFiling_id/:type"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateUpdateClaimsFiling />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EMAIL_TEMPLATE}
        element={
          <Suspense fallback={<CustomLoading />}>
            <ViewEmailTemplate />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_EMAIL_TEMPLATE}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateUpdateEmailTemplate />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_EMAIL_TEMPLATE + ":template_id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateUpdateEmailTemplate />
          </Suspense>
        }
      />
      {/* DOCUMENT */}
      <Route
        errorElement={<ErrorLoader />}
        path={DBK_LIST}
        element={
          <Suspense fallback={<CustomLoading />}>
            <ViewDbk />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={CREATE_DBK}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateUpdateDbk />
          </Suspense>
        }
      />
      <Route
        errorElement={<ErrorLoader />}
        path={EDIT_DBK + ":id"}
        element={
          <Suspense fallback={<CustomLoading />}>
            <CreateUpdateDbk />
          </Suspense>
        }
      />

      {/* Spring LOCKBOX Routes */}
      <Route
        errorElement={<ErrorLoader />}
        path={RETRIVE_SPRING_LOCKBOX}
        element={
          <Suspense fallback={<CustomLoading />}>
            <SpringLockbox />
          </Suspense>
        }
      />

      <Route
        path="*"
        element={
          <Suspense fallback={<CustomLoading />}>
            <ErrorLoader />
          </Suspense>
        }
      />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Root>
    {/* You can keep the Footer outside of the RouterProvider */}
    <Suspense fallback={<div>Loading App...</div>}>
      <RouterProvider router={router} />
    </Suspense>
  </Root>
);
