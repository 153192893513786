import React, { useState } from 'react';
import Logo from "../../../assets/img/Xtract.png";
import { getAllTimeZone } from '../../../Redux/header/action';
import profileimg from "../../../assets/img/profile-img.jpg";
import { Link } from 'react-router-dom';
import Modal from "react-modal";
import ResetPasswordForm from '../../../components/DashBoard/Password/ResetPasswordForm';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { logooutSession } from '../../../Redux/auth/action';
import { NOTIFICATION_EDIT } from '../../../routeNames';
import ChangeTimeZone from '../../../components/DashBoard/TimeZone/ChangeTimeZone';
import { getSelectedTimezone } from '../../../Redux/user/action';
import DeleteSweetAlert from '../../../components/SweetAlert/DeleteSweetAlert';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { changeAllTitleFunction, setTriggerIframePageLoad } from '../../../components/DashBoard/Dashboard/functions';


const Header = (props) => {
    const [options, setOptions] = useState([]);
    const [group, setGroup] = useState([])
    const { setGroupId, ToastMessage, notificationData, message } = props;
    const { is_password_reset_required, groups } = useSelector((state) => state?.auth?.user)
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [timeZonemodalIsOpen, setTimeZoneIsOpen] = React.useState(false);
    const [selectedTimezoneOptions, setSelectedTimezoneOptions] = React.useState(null);
    function closeModal() {
        setIsOpen(false);
    }

    const formik = useFormik({
        initialValues: {
            timezone: ""
        },
        validationSchema: Yup.object().shape({
            timezone: Yup.string().required("Required")
        }),
        validate: (values) => {
            let errors = {}
            if (values?.timezone == "") {
                errors.timezone = "Required"
            }
            return errors;
        },
        onSubmit: (values) => {
            let formData = new FormData();    //formdata object
            formData.append('timezone', values.timezone);
            showAlert()
        }
    })

    const showAlert = () => {
        Swal.fire({
            backdrop: true,
            allowOutsideClick: false,
            title: "Are you sure",
            text: "All the bottom tabs will be closed and the pages will be reloaded",
            icon: "warning",
            showConfirmButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#6ba2b6",
            denyButtonColor: "#575757",
            showDenyButton: true,
            className: {
                popup: 'my-custom-popup',
            },
        }).then((response) => {

            if (response.isConfirmed) {
                getAllTimeZone(formik.values.timezone)(response => {
                    if (!response?.data?.status && response?.status && response?.status_code === 201) {
                        localStorage.setItem('timezone', formik.values.timezone)

                        parent.location.reload()
                        closeModalTimeZone()
                        setShowToastMessage({
                            status: true,
                            message: 'Success',
                            type: 'success'
                        })

                    } else {
                        const errors = response?.response?.data?.errors?.current_password[0];
                        formik.setErrors({
                            ...formik,
                            timezone: errors

                        })
                    }
                })
            } else {
                closeModalTimeZone()
            }
        })
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '18%'
        },
    };

    const timeZonecustomStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            overflow: 'none'
        },
    };


    const [showToastPassword, setShowToastPassword] = useState(false);
    const [showErrorPassword, setShowErrorPassword] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState('');
    const [timeZone, setTimeZone] = useState([])
    const [rotate, setRotate] = useState(false);
    const rotateTransform = rotate ? "rotate(180deg)" : "rotate(0)"
    const user = JSON.parse(localStorage.getItem("user"));
    const [isActive, setIsActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [timeZoneLabel, settimeZoneLabel] = useState("");
    const bottom_tabs = useSelector((state) => state?.bottomTab);



    const handleClick = () => {
        setRotate(!rotate);

        setIsActive(current => !current);
        if (isActive) {
            const toggleRemovee = document.body.classList.remove('toggle-sidebar')
            const toggleRemove = document.getElementById('navbar').classList.remove('expander')
        }
        else {
            const toggleOpenn = document.body.classList.add('toggle-sidebar')
            const toggleOpen = document.getElementById('navbar').classList.add('expander')
            const toggleActiveRemove = document.getElementsByClassName('nav-link');
            Array.from(toggleActiveRemove).forEach(element => {
                element.classList.add('collapsed');

            });
            const collapse = document.getElementsByClassName('nav-content');
            Array.from(collapse).forEach(element => {

                element.classList.remove('show');

            });
        }
    }

    const __passwordToast = (status, message) => {
        if (status == 'success') {
            setShowToastPassword(true)
            closeModal()
        }
        else {
            setShowErrorPassword(true)
            setShowErrorMessage(message)
        }
    }

    useEffect(() => {
        if (showToastPassword == true) {
            const timer = setTimeout(() => {
                setShowToastPassword(false)
            }, [2000]);
            return () => clearTimeout(timer);
        }
    }, [showToastPassword]);

    const handleOnSelectChange = (e) => {
        Swal.fire({
            backdrop: true,
            allowOutsideClick: false,
            title: "Are you sure",
            text: "All the bottom tabs will be closed and the pages will be reloaded",
            icon: "warning",
            showConfirmButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#6ba2b6",
            denyButtonColor: "#575757",
            showDenyButton: true,
            className: {
                popup: 'my-custom-popup',
            },
        }).then((response) => {

            if (response.isConfirmed) {
                setGroup(e?.value)
                setGroupId(e?.value)

            } else {

            }
        })

    }

    useEffect(() => {
        groups?.map((i) => {
            options.push({ label: i.name, value: i.id })
        })
        if (localStorage.getItem('user_group')) {
            setGroup(JSON.parse(localStorage.getItem('user_group')))
            setGroupId(JSON.parse(localStorage.getItem('user_group')))
        }
        else {
            setGroup(options[0])
            setGroupId(options[0])
        }
    }, [groups])

    useEffect(() => {
        // trigger iframe page load to Iframe Compo
        setTriggerIframePageLoad()

        getSelectedTimezone()((response) => {
            setSelectedTimezoneOptions(response?.data)
        })

    }, [])

    useEffect(() => {

        if (selectedTimezoneOptions) {
            setTimeZone(TimeZone)
            const TimeZone = localStorage.getItem('timezone');
            const a = selectedTimezoneOptions.find((i) => i.is_selected) ?? "UTC"
            setSelectedOption(a)
            settimeZoneLabel(a == "UTC" ? a : a?.label)
            formik.setValues({
                timezone: TimeZone,
            })
        }

    }, [selectedTimezoneOptions]);

    function closeModalTimeZone() {
        setTimeZoneIsOpen(false);
        const TimeZone = localStorage.getItem('timezone');
        var a = selectedTimezoneOptions.find((i) => i.value === TimeZone)
        setSelectedOption(a)
        formik.setValues({
            timezone: TimeZone,
        })
    }

    const [isSwal, setIsSwal] = useState({
        show: false,
    })

    const closeTab = () => {
        const refresh = localStorage.getItem('refresh_token')
        logooutSession(refresh)((response) => {
            if (response && response?.status) {
                localStorage.clear();
                parent.open("about:blank", "_self");
                parent.close();
            }
        })
    }

    const [showToastMessage, setShowToastMessage] = useState({
        status: false,
        message: '',
        type: '',
    })
    const handleGoBack = () => {
        const json_tabs = window.parent.localStorage.getItem('bottomTab')
        let tabs = JSON.parse(json_tabs);
        const priviousTab = tabs?.currentTab?.prevTabs[tabs?.currentTab?.prevTabs.length - 1]

        if (priviousTab) {
            window.parent.localStorage.setItem('isBackActive', 'true')
            const baseUrl = window.location.origin
            const previousURL = priviousTab?.link.replace(baseUrl, "");
            const previousLabel = priviousTab?.label;
            changeAllTitleFunction(previousLabel, previousURL)
        } else {
            // changeAllTitleFunction('Dashboard', '/admin/dashboard')

        }
    };
    return (
        <React.Fragment>

            <header id="header" className="header fixed-top d-flex align-items-center">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" onClose={() => setShowToastPassword(!showToastPassword)} show={showToastPassword} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" onClose={() => setShowErrorPassword(!showErrorPassword)} show={showErrorPassword} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>{showErrorMessage}</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                {ToastMessage &&
                    <ToastContainer className="p-3" position={"top-end"}>
                        <Toast bg="success" show={ToastMessage && ToastMessage == 'success'} animation={true} delay={2000} autohide>
                            <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                        </Toast>
                        <Toast bg="danger" show={ToastMessage && ToastMessage == 'error' ? true : false} animation={true} delay={2000} autohide>
                            <Toast.Body ><span className='text-white'>{message ? message : "Failed"}</span></Toast.Body>
                        </Toast>
                    </ToastContainer>
                }
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg={showToastMessage?.type} onClose={() => setShowToastMessage({
                        status: false,
                        message: '',
                        type: ''
                    })} show={showToastMessage?.status} animation={true} delay={2000} autohide>
                        <Toast.Body ><span className='text-white'>{showToastMessage?.message}</span></Toast.Body>
                    </Toast>

                </ToastContainer>

                <div className="d-flex align-items-center justify-content-between">
                    <Link onClick={() => changeAllTitleFunction('Dashboard', "/admin/dashboard")} className="logo d-flex align-items-center">
                        <img src={Logo} alt="Xtract" />

                    </Link>
                    <div className="dashboard_togglebtn" onClick={handleClick} style={{ transform: rotateTransform, transition: "all 0.2s linear" }} >
                        <span className="svg-icon svg-icon-2 rotate-180">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="currentColor"></path>
                                <path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="currentColor"></path>
                            </svg>
                        </span>
                    </div>
                    <div className='ms-2' onClick={() => { handleGoBack() }}>
                        <span className="">
                            <i style={{ fontSize: '20px', cursor: 'pointer' }} className="bi bi-arrow-left-square"></i>
                        </span>
                    </div>
                </div>


                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">
                        <div className='me-3'>

                            <p onClick={() => { setTimeZoneIsOpen(true) }} className='fw-bolder' style={{ color: "#198754", cursor: 'pointer' }}>{timeZoneLabel}</p>
                        </div>

                        {options?.length > 1 &&
                            <li className='me-3'>
                                <Select
                                    className="basic-single w-200px"
                                    classNamePrefix="select"
                                    value={group}
                                    width={200}
                                    name="users"
                                    options={options}
                                    onChange={(e) => { handleOnSelectChange({ value: e }) }}
                                />
                            </li>
                        }
                        <li className="nav-item dropdown">

                            <Link className="nav-link nav-icon" data-bs-toggle="dropdown">
                                <i className="bi bi-chat-left-text"></i>
                                <span className="badge bg-success badge-number">{notificationData?.count}</span>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages profile" style={{
                                maxHeight: "410px",
                                overflowY: "scroll"
                            }}>
                                {notificationData?.messages && notificationData?.messages?.length > 0 && notificationData?.messages?.map((message, index) =>
                                    <React.Fragment key={index}>
                                        <div style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => { changeAllTitleFunction('Notifications', message?.path !== "" ? message?.path : NOTIFICATION_EDIT + message?.pk) }}>

                                            <li className="message-item">
                                                <div>
                                                    <h4>{message?.title}</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                        </div>
                                    </React.Fragment>

                                )}




                                <li style={{
                                    cursor: 'pointer', position: " sticky",
                                    zIndex: 999,
                                    bottom: "0px",
                                    background: "#fff"
                                }} onClick={() => { changeAllTitleFunction('Notifications', '/notifications') }} className="dropdown-footer">
                                    <span >Show all messages</span>
                                </li>

                            </ul>

                        </li>

                        <li className="nav-item dropdown pe-3">

                            <Link className="nav-link nav-profile d-flex align-items-center pe-0" data-bs-toggle="dropdown">
                                <img src={profileimg} alt="Profile" className="rounded-circle" />
                                <span className="d-none d-md-block dropdown-toggle ps-2">{user?.username}</span>
                            </Link>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li className="dropdown-header">
                                    <h6>{user?.username}</h6>
                                    <span>{user?.is_admin ? "Admin" : "Staff"}</span>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => { setIsOpen(true) }}>
                                        <i className="bi bi-gear"></i>
                                        <span>Change Password</span>
                                    </Link>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => { setTimeZoneIsOpen(true) }}>
                                        <i className="bi bi-alarm"></i>
                                        <span>Change Time Zone</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <Link className="dropdown-item d-flex align-items-center"
                                        onClick={() => closeTab()}

                                    >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                            </ul>
                        </li>

                    </ul>
                </nav>

                <Modal
                    isOpen={modalIsOpen || is_password_reset_required}
                    ariaHideApp={false}
                    onRequestClose={() => { closeModal() }}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <ResetPasswordForm __passwordToast={__passwordToast} closeModal={closeModal} />
                </Modal>
                <Modal
                    isOpen={timeZonemodalIsOpen}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                    onRequestClose={() => { closeModalTimeZone() }}
                    style={timeZonecustomStyles}
                    contentLabel="Example Modal"
                >
                    <ChangeTimeZone
                        formik={formik}
                        showToastMessage={showToastMessage}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        setShowToastMessage={setShowToastMessage}
                        closeModal={closeModalTimeZone}
                        timeZone={timeZone}
                        selectedTimezoneOptions={selectedTimezoneOptions} />
                </Modal>
                <DeleteSweetAlert isSwal={isSwal} setIsSwal={setIsSwal} />

            </header>
        </React.Fragment>
    )
}

export default Header;