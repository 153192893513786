import React from "react";
import styled from "styled-components";

const CustomLoading = () => (
  <MainConatiner className="custom-loader-container">
    <div className="spinner"></div>
    {/* <h1>Loading, please wait...</h1> */}
  </MainConatiner>
);

export default CustomLoading;
const MainConatiner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4; /* Optional: change background color if needed */

  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1); /* Light gray border */
    border-top: 8px solid #3498db; /* Blue color for spinner */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 0.8s linear infinite; /* Faster spin by reducing time */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  h1 {
    margin-top: 20px;
    color: #333;
    font-family: Arial, sans-serif;
    font-size: 1.5rem;
  }
`;
