import React from 'react'
import DataTable from 'react-data-table-component';
import { createTheme } from 'react-data-table-component';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import useAutocomplete from '@mui/base/useAutocomplete';
import styled from 'styled-components/macro';
import { useState } from 'react';

const queueDatatableCustomStyles = {
    rows: {
        style: {
            minHeight: '20px', // override the row height
            fontSize: '1.2em',
            color: '#666666',
            backgroundColor: '#fff',
            borderBottom: '1px solid #eff5f6 !important'

            // width: '100%'

        },
    },

    headCells: {
        style: {
            backgroundColor: '#eff5f6',
            border: '1px solid #fff !important',
            borderBottom: '1px solid #fff !important',

            paddingLeft: '5px', // override the cell padding for head cells
            paddingRight: '5px',
            textTransform: "uppercase",
            fontSize: '0.9em',
            // fontWeight: '800',
            color: '#016b87 !important',
            opacity: 1,
            minHeight: "100px",

        },
        draggingStyle: {
            cursor: 'move',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            width: "100%",
            fontColor: '#000000',
            fontWeight: "550",
            borderRight: '1px solid #eff5f6 !important',
        },
    },

};
// createTheme(
//     'solarized',
//     {
//         text: {
//             primary: '#000000',
//             secondary: 'rgba(0, 0, 0, 0.54)',
//             disabled: 'rgba(0, 0, 0, 0.38)',
//         },
//         background: {
//             default: '#FFFFFF',
//         },
//         context: {
//             background: '#e3f2fd',
//             text: 'rgba(0, 0, 0, 0.87)',
//         },
//         divider: {
//             default: '#f4f4f4',
//         },
//         button: {
//             default: 'rgba(0,0,0,.54)',
//             focus: 'rgba(0,0,0,.12)',
//             hover: 'rgba(0,0,0,.12)',
//             disabled: 'rgba(0, 0, 0, .18)',
//         },
//         selected: {
//             default: '#e3f2fd',
//             text: 'rgba(0, 0, 0, 0.87)',
//         },
//         highlightOnHover: {
//             default: '#EEEEEE',
//             text: 'rgba(0, 0, 0, 0.87)',
//         },
//         striped: {
//             default: '#FAFAFA',
//             text: 'rgba(0, 0, 0, 0.87)',
//         },
//     }
// );

function TatDetailDataTable(props) {
    const { data, setIsCopieValue } = props
    const [isFocusedStatus, setIsFocusedStatus] = useState(false);
    const status = [
        { value: "1", label: "My Work" },
        { value: "2", label: "QA Review" },
    ]
    const columns = [
        {
            name: "",
            selector: row => row.client ? row.client : "",
            sortable: false,
            width: '15%',

        },
        {
            name: "",
            selector: row => row.facility ? row.facility : "",
            sortable: false,
            width: '13%',


        },
        {
            name: "",
            selector: row => <span onCopy={(e) => { setIsCopieValue(row.internal_tat) }}>{row.internal_tat}</span>,
            sortable: false,
            width: '11%',


        },
        {
            name: 'CLOSED TO TAT',
            selector: row => row.close_to_tat ? row.close_to_tat : 0,
            sortable: false,
            right: true,
            width: '6%',

        },
        {
            name: 'Missed TAT',
            selector: row => row.missed_tat ? row.missed_tat : 0,
            sortable: false,
            right: true,
            width: '5.5%',

        },
        {
            name: 'File SPlit',
            selector: row => row.split_files ? row.split_files : 0,
            sortable: false,
            right: true,
            width: '4.5%',

        },
        {
            name: 'Total Jobs',
            selector: row => row.total_jobs ? row.total_jobs : 0,
            sortable: false,
            right: true,
            width: '5%',

        },
        {
            name: 'IMPORTED',
            selector: row => row.my_work_pending_jobs ? row.my_work_pending_jobs : 0,
            sortable: false,
            right: true,
            width: '5%',

        },
        {
            name: 'My Work',
            selector: row => row.my_work ? row.my_work : 0,
            sortable: false,
            right: true,
            width: '4.5%',

        },
        {
            name: 'QA Review',
            selector: row => row.qa_review_pending_jobs ? row.qa_review_pending_jobs : 0,
            sortable: false,
            right: true,
            width: '6%',

        },
        {
            name: 'QA',
            selector: row => row.qa_jobs ? row.qa_jobs : 0,
            sortable: false,
            right: true,
            width: '3.5%',

        },
        {
            name: 'Snipping',
            selector: row => row.snipping_jobs ? row.snipping_jobs : 0,
            sortable: false,
            right: true,
            width: '5%',

        },
        {
            name: 'SN Inprocess',
            selector: row => row.snipping_pending_jobs ? row.snipping_pending_jobs : 0,
            sortable: false,
            right: true,
            width: '6%',

        },
        {
            name: 'Pending',
            selector: row => row.pending_jobs ? row.pending_jobs : 0,
            sortable: false,
            right: true,
            width: '4.5%',

        },
        {
            name: 'Completed',
            selector: row => row.completed_jobs ? row.completed_jobs : 0,
            sortable: false,
            right: true,
            width: '5.5%',

        },
    ];

    return (
        <DataTable
            noTableHead={true}
            columns={columns}
            data={data}
            customStyles={queueDatatableCustomStyles}
        // theme="solarized"

        />
    )
}

export default TatDetailDataTable


